import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

const categories = (state = [], action) => {
  switch (action.type) {
    case "FETCH_CATEGORIES":
      return action.categories;

    default:
      return state;
  }
};

const news = (state = [], action) => {
  switch (action.type) {
    case "FETCH_NEWS":
      return action.news;
    default:
      return state;
  }
};

const covidList = (state = [], action) => {
  switch (action.type) {
    case "FETCH_COVID_LIST":
      return action.covidList;
    default:
      return state;
  }
};

const nextYearOffers = (state = [], action) => {
  switch (action.type) {
    case "FETCH_NEXT_YEAR_OFFERS":
      return action.nextYearOffers;
    default:
      return state;
  }
};

const offers = (state = [], action) => {
  switch (action.type) {
    case "FETCH_OFFERS":
      return action.offers;
    default:
      return state;
  }
};

const listOptions = (
  state = { listSort: 0, gastroFilter: null, foundLocations: [] },
  action
) => {
  switch (action.type) {
    case "SET_LIST_SORT":
      return { ...state, listSort: action.listSort };

    case "SET_GASTRO_FILTER":
      return {
        ...state,
        gastroFilter: action.gastroFilter,
        foundLocations: action.foundLocations,
      };

    case "SHOW_GASTRO_FILTER":
      return { ...state, showFilterModal: action.showFilterModal };

    default:
      return state;
  }
};

const flipsApp = combineReducers({
  categories,
  news,
  nextYearOffers,
  offers,
  listOptions,
  covidList,
});

export const fetchCategories = () => {
  return (dispatch, getState) => {
    fetch("/coupons_2018.json?v=2020", { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        json = json.map((j, i) => {
          j.cat_index = i;

          var count = 0;

          j.locations.forEach((loc) => {
            count = count + loc.coupons.length;
          });

          console.log(j.name, j.locations.length, count);

          j.locations.forEach((loc) => {
            if (loc.page % 2 === 0) {
              loc.coupons.reverse();
            }
          });

          return j;
        });

        return dispatch({ type: "FETCH_CATEGORIES", categories: json });
      });
  };
};

export const fetchNews = () => {
  return (dispatch, getState) => {
    fetch(
      "https://greenstonesteststorage.blob.core.windows.net/flips-2025/news.json",
      { credentials: "same-origin" }
    )
      .then((response) => response.json())
      .then((json) => {
        return dispatch({ type: "FETCH_NEWS", news: json });
      });
  };
};

export const fetchCovidList = () => {
  return (dispatch, getState) => {
    fetch("/covid.json?v=2020_6", { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        return dispatch({ type: "FETCH_COVID_LIST", covidList: json });
      });
  };
};

export const fetchNextYearOffers = () => {
  return (dispatch, getState) => {
    fetch("/offers2025.json?v=3", { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        json = json.map((j, i) => {
          j.cat_index = i;
          return j;
        });

        return dispatch({
          type: "FETCH_NEXT_YEAR_OFFERS",
          nextYearOffers: json,
        });
      });
  };
};

export const fetchOffers = () => {
  return (dispatch, getState) => {
    fetch("/offers2025.json?v=3", { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        json = json.map((j, i) => {
          j.cat_index = i;
          return j;
        });

        return dispatch({ type: "FETCH_OFFERS", offers: json });
      });
  };
};

let store = createStore(flipsApp, applyMiddleware(thunkMiddleware));

//store.dispatch(fetchCategories());
store.dispatch(fetchNews());
store.dispatch(fetchNextYearOffers());
//store.dispatch(fetchOffers());

//store.dispatch(fetchCovidList());

export default store;
